import React from "react"
import { graphql } from "gatsby"
import Connection from "../components/Connection"

const LifeGroupsPageWIP = ({data}) => (
  <Connection
    context             = "life-groups"
    seoTitle            = "Life Groups"
    // pageTitlePrefix     = "Connect"
    pageTitlePrefix     = "Life Groups"
    // pageTitle           = {<>Meet other believers through <span>life groups</span></>}
    pageTitle           = {<>Give <span>life together</span> through discipleship, care, missions, and multiplication.</>}
    noRecordsIndexTitle = "No Results"
    noRecordsIndexText  = "trying another selection to find a life group."
    collection          = {data.allDatoCmsConnection.edges}
  />
)

export default LifeGroupsPageWIP


// GraphQL
// -------

export const query = graphql`
  query LifeGroupQueryIndexWIP {
    allDatoCmsConnection(
      filter: {connectionType: {slug: {eq: "life-group"}}}
    ) {
      edges {
        node {
          ...ConnectionFragment
        }
      }
    }
  }
`
